import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Container} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Logo = ({title}) => {
    return (
        <div className="bg-secondary position-relative">
            <StaticImage src="../images/page_bg_02.svg" layout="fullWidth"
                         alt="skaterhockey.de"
                         imgClassName="img-fluid"/>
            <Container className="position-relative">
                <h1 className="position-absolute bottom-0 start-0">{title}</h1>
            </Container>
        </div>
    );
};

Logo.propTypes = {
    title: PropTypes.node.isRequired,
};

export default Logo;
