import React from 'react';
import {Helmet} from 'react-helmet';
import Logo from '../components/Logo';
import Block from '../components/Block';

const ImprintPage = () => {
    return (
        <>
            <Helmet title="Impressum"/>
            <Logo title="Impressum"/>
            <Block>
                <h4>Angaben gemäß § 5 TMG:</h4>

                <p>Deutscher Rollsport und Inline-Verband e.V.<br/>
                    Otto-Fleck-Schneise 10a, 60528 Frankfurt am Main</p>

                <h5>Vertreten durch:</h5>

                <p>Frau Irmelin Otten, Präsidentin</p>

                <h5>Kontakt:</h5>
                <dl>
                    <dt>Telefon:</dt>
                    <dd>069 97761737</dd>
                    <dt>E-Mail:</dt>
                    <dd>
                        <a href="mailto:info@driv.de">info@driv.de</a>
                    </dd>
                </dl>

                <h5>Registereintrag:</h5>
                <p>Eintragung im Vereinsregister.<br/>
                    Registergericht am Amtsgericht Frankfurt am Main.<br/>
                    Registernummer: VR 4925</p>

                <h5>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h5>
                <p>Deutscher Rollsport und Inline-Verband e.V.<br/>
                    Christian Fay, Otto-Fleck-Schneise 10a, 60528 Frankfurt am Main<br/>
                    Telefon: 069 97761737, <a href="mailto:info@driv.de">info@driv.de</a></p>

                <h4>Streitschlichtung</h4>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.</p>

                <h4>Haftung für Inhalte</h4>
                <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
                    den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                    jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                    oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                    allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                    ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                    von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

                <h4>Haftung für Links</h4>
                <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                    Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                    Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                    mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                    nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                    konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

                <h4>Urheberrecht</h4>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
                    des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
                    privaten, nicht kommerziellen Gebrauch gestattet.<br/>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                    bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
                    wir derartige Inhalte umgehend entfernen.</p>

                <p className="small text-muted">Hinweis: erstellt mit dem Impressum-Generator von e-recht24.de</p>
            </Block>
        </>
    )
};

export default ImprintPage;
