import PropTypes from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.color};
    background: linear-gradient(90deg, ${props => props.color} 0%, rgba(0, 0, 0, 0) 50%);
`;

const Block = ({variant, image, children}) => {
    const bgClass = variant ? `bg-${variant}` : '';
    const containerClass = !image ? `py-5 ${bgClass}` : `py-5 py-md-0 ${bgClass}`;

    let gradientColor = null;
    if (image) {
        switch (variant) {
            case 'primary':
                gradientColor = 'var(--bs-primary)';
                break;
            case 'secondary':
                gradientColor = 'var(--bs-secondary)';
                break;
            case 'light':
                gradientColor = 'var(--bs-light)';
                break;
            case 'bayern':
                gradientColor = 'var(--sh-bayern-color)';
                break;
            default:
                gradientColor = null;
                break;
        }
    }

    return (
        <div className={containerClass}>
            <Container>
                {image ? (
                    <Row>
                        <Col md={8}>
                            <div className="pt-3 pt-md-5 pb-0 pb-md-5">
                                {children}
                            </div>
                        </Col>
                        <Col md={4} className="order-first order-md-last">
                            <div className="position-relative h-100">
                                {React.cloneElement(image, {
                                    imgClassName: 'img-fluid',
                                    className: 'h-100 d-block'
                                })}
                                {gradientColor && <Overlay color={gradientColor} className="d-none d-md-block"/>}
                            </div>
                        </Col>
                    </Row>
                ) : children}
            </Container>
        </div>
    )
};

Block.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'light', 'bayern']),
    image: PropTypes.node,
    children: PropTypes.node,
}

export default Block;
